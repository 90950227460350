import React, { useState, useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import { AnimatePresence, motion } from "framer-motion";
import {
  PhoneIcon,
  MailIcon,
  LocationMarkerIcon,
} from "@heroicons/react/solid";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import brazing from "../images/brazing.png";
import brazingTablet from "../images/brazing-tablet.png";
import { messages } from "../i18n-translations";

import bgMap from "../images/bg-map.png";
import NavMenu from "../components/nav-menu";
import Header from "../components/header";
import Button from "../components/button";
import RubberPlasticIcon from "../components/svgs/rubber-plastic-icon";
import MachineIcon from "../components/svgs/machine-icon";
import AssemblyIcon from "../components/svgs/assembly-icon";
import ImageSlider from "../components/image-slider";
import Footer from "../components/footer";
import Solutions from "../components/solutions";

const IndexPage: React.FC = ({ children }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [navTopMargin, setNavTopMargin] = React.useState(40);
  const header = useRef<HTMLDivElement>(null);

  const breakpoints = useBreakpoint();
  const isScreenXl = breakpoints.xl;
  const isScreenLg = breakpoints.lg;
  const isScreenMd = breakpoints.md;

  const currentYearStringLast2 = new Date().getFullYear().toString().slice(-2);
  const currentYearLast2 = Number(currentYearStringLast2);

  const rubberYears = currentYearLast2 - 2;
  const machiningYears = currentYearLast2 - 8;
  const assemblyYears = currentYearLast2 - 6;

  useEffect(() => {
    if (header.current) setNavTopMargin(header.current.clientHeight);
  }, [header]);

  {
    //#region slides
  }

  let partnerSlides =
    !isScreenXl && !isScreenMd
      ? [
          <div className="bg-white shadow-xl">
            <StaticImage src={"../images/partner-siemens.png"} alt="siemens" />
          </div>,
          <div className="bg-white shadow-xl">
            <StaticImage src={"../images/partner-drager.png"} alt="drager" />
          </div>,
          <div className="bg-white shadow-xl">
            <StaticImage src={"../images/partner-siemens.png"} alt="siemens" />
          </div>,
          <div className="bg-white shadow-xl">
            <StaticImage src={"../images/partner-drager.png"} alt="drager" />
          </div>,
        ]
      : [
          <div className="flex gap-10">
            <div className="bg-white shadow-xl">
              <StaticImage
                src={"../images/partner-siemens.png"}
                alt="siemens"
              />
            </div>
            <div className="bg-white shadow-xl">
              <StaticImage src={"../images/partner-drager.png"} alt="drager" />
            </div>
          </div>,
          <div className="flex gap-10">
            <div className="bg-white shadow-xl">
              <StaticImage src={"../images/partner-drager.png"} alt="drager" />
            </div>
            <div className="bg-white shadow-xl">
              <StaticImage
                src={"../images/partner-siemens.png"}
                alt="siemens"
              />
            </div>
          </div>,
        ];
  {
    //#endregion
  }

  {
    //#region Images
  }
  const heroImageMobile = (
    <StaticImage
      src={"../images/home-hero-image-mobile.png"}
      alt="jintai"
      loading="eager"
      style={{ height: `100%`, width: "100%" }}
    />
  );

  const heroImageMd = (
    <StaticImage
      src={"../images/hero-md.webp"}
      alt="jintai"
      loading="eager"
      style={{ height: `100%`, width: "100%" }}
    />
  );

  const heroImageLg = (
    <StaticImage
      src={"../images/hero-lg.webp"}
      alt="jintai"
      loading="eager"
      style={{ height: `100%`, width: "100%" }}
      objectFit="cover"
    />
  );
  {
    //#endregion
  }

  const formAttributes = {
    name: "contact",
    "data-netlify": true,
    method: "post",
  };

  return (
    <main className="relative z-0 w-full h-screen">
      {
        //#region Mobile Nav
      }
      {!isScreenXl && (
        <AnimatePresence>
          {isNavOpen && (
            <div
              className={`fixed flex inset-0 z-101 w-screen h-screen bg-transparent`}
            >
              <motion.div
                animate={{ opacity: 1 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                className="flex w-full origin-top"
                key="nav"
                style={{ marginTop: navTopMargin }}
              >
                <NavMenu />
              </motion.div>
            </div>
          )}
        </AnimatePresence>
      )}
      {
        //#endregion
      }
      <div ref={header} className="fixed top-0 z-110 w-full bg-white">
        <Header
          isNavOpen={isNavOpen}
          onMenuButtonPress={() => setIsNavOpen(!isNavOpen)}
        />
      </div>
      <div
        className="flex flex-col w-full h-screen"
        style={{ marginTop: navTopMargin }}
      >
        {
          //#region Hero
        }
        <div className="relative flex flex-1 bg-blue-300">
          <div
            style={{
              height: `calc(100vh - ${
                header.current && header.current.clientHeight
              }px)`,
              width: "100vw",
            }}
          >
            {isScreenXl
              ? heroImageLg
              : isScreenMd
              ? heroImageMd
              : heroImageMobile}
          </div>
          <div className="absolute inset-0">
            <div className="flex flex-col items-center px-12 text-center text-white md:text-left md:items-start md:w-7/12 lg:px-20 xl:container ">
              <h1 className="my-10 text-2xl md:text-3xl lg:text-4xl xl:text-5xl lg:leading-tight lg:mt-24 xl:w-7/12">
                {messages.en.globalOneStopManufacturingSolution}
              </h1>
              <div className="text-base stroke-1 lg:text-lg xl:text-2xl">
                {messages.en.easyAndSimpleSolutionText}
              </div>
              <Button className="w-4/6 mt-8 lg:w-80 xl:px-4 xl:py-6 lg:text-xl xl:text-2xl">
                {messages.en.learnAboutJintai}
              </Button>
            </div>
          </div>
        </div>
        {
          //#endregion
        }

        {
          //#region About Us
        }
        <div className="flex flex-col items-center px-6 py-10 lg:flex-row-reverse lg:px-20 xl:container xl:mx-auto lg:py-14">
          <div className="flex justify-center lg:flex-1 lg:justify-end lg:items-center">
            <StaticImage
              src="../images/home-about-us.png"
              alt="about-us"
              className="lg:w-10/12"
            />
          </div>
          <div className="flex flex-col items-center text-center md:flex-row md:items-start md:text-left md:gap-4 lg:flex-1 lg:flex-col">
            <div className="flex flex-col md:w-9/12 lg:w-full">
              <div className="py-6 lg:pb-2">{messages.en.aboutUs}</div>
              <div className="mx-8 text-2xl md:mx-0 md:text-3xl">
                {messages.en.oneStopSolution}
              </div>
              <div className="py-6 lg:pb-0">
                {messages.en.oneStopSolutionDescription}
              </div>
              {isScreenMd && (
                <Button className="w-5/12 py-4 mt-8 text-lg text-white">
                  {messages.en.learnMore}
                </Button>
              )}
            </div>

            <div className="flex flex-col items-center justify-between h-full space-y-8 lg:flex-row lg:space-y-1">
              <div className="flex items-center w-8/12 pt-6 md:w-full lg:pt-0">
                <div className="px-4">
                  <RubberPlasticIcon />
                </div>
                <div className="flex flex-col items-start text-left">
                  <div className="pb-1 text-blue-600">
                    <span className="pr-4 text-4xl">{rubberYears}</span>
                    {messages.en.years}
                  </div>
                  <div className="text-xs">
                    {messages.en.rubberPlasticExperience}
                  </div>
                </div>
              </div>
              <div className="flex items-center w-8/12 md:w-full">
                <div className="px-4">
                  <MachineIcon />
                </div>
                <div className="flex flex-col items-start text-left">
                  <div className="pb-1 text-blue-600">
                    <span className="pr-4 text-4xl">{machiningYears}</span>
                    {messages.en.years}
                  </div>
                  <div className="text-xs">
                    {messages.en.precisionMachiningExperience}
                  </div>
                </div>
              </div>
              <div className="flex items-center w-8/12 md:w-full">
                <div className="px-4">
                  <AssemblyIcon />
                </div>
                <div className="flex flex-col items-start text-left">
                  <div className="pb-1 text-blue-600">
                    <span className="pr-4 text-4xl">{assemblyYears}</span>
                    {messages.en.years}
                  </div>
                  <div className="text-xs">
                    {messages.en.assemblyExperience}
                  </div>
                </div>
              </div>
            </div>
            {!isScreenMd && (
              <Button
                big
                className="w-full py-6 mt-8 text-lg text-white lg:w-56 lg:py-3 lg:mt-2"
              >
                {messages.en.learnMore}
              </Button>
            )}
          </div>
        </div>
        {
          //#endregion
        }

        {
          //#region Philosophy
        }
        <div className="flex flex-col items-center p-6 text-center md:text-left md:items-start lg:text-center lg:items-center lg:px-20 lg:py-14 xl:container xl:mx-auto">
          <div className="text-2xl md:text-3xl">
            {messages.en.jintaiPhilosophy}:
          </div>
          <div className="flex flex-wrap w-full">
            <div className="flex justify-center w-full lg:lg:w-1/3">
              <div className="relative z-0 flex items-start w-full mt-10 md:w-5/12 lg:w-11/12">
                <StaticImage
                  src={"../images/bg-01.png"}
                  alt="jintai"
                  objectFit="contain"
                  className="absolute w-auto h-full"
                />
                <div className="z-20 flex flex-col items-start justify-start w-full mt-6 text-lg text-left ">
                  <div>
                    <span className="text-blue-600">01</span>{" "}
                    {messages.en.customerFocused}
                  </div>
                  <div className="mt-4 text-base">
                    {messages.en.customerFocusedText}
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:flex md:justify-around lg:w-2/3">
              <div className="relative z-0 flex items-start w-full mt-8 md:w-5/12">
                <StaticImage
                  src={"../images/bg-02.png"}
                  alt="jintai"
                  objectFit="contain"
                  className="absolute w-auto h-full"
                />
                <div className="z-20 flex flex-col items-start justify-start w-full mt-6 text-lg text-left">
                  <div>
                    <span className="text-blue-600">02</span>{" "}
                    {messages.en.qualityAndOnTime}
                  </div>
                  <div className="mt-4 text-base">
                    {messages.en.qualityAndOnTimeText}
                  </div>
                </div>
              </div>
              <div className="relative z-0 flex items-start w-full mt-8 md:w-5/12">
                <StaticImage
                  src={"../images/bg-03.png"}
                  alt="jintai"
                  objectFit="contain"
                  className="absolute w-auto h-full"
                />
                <div className="z-20 flex flex-col items-start justify-start w-full mt-6 text-lg text-left">
                  <div>
                    <span className="text-blue-600">03</span>{" "}
                    {messages.en.continuousImprovement}
                  </div>
                  <div className="mt-4 text-base">
                    {messages.en.continuousImprovementText}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          //#endregion
        }

        {
          //#region One Stop Solution
        }
        <div className="flex flex-col items-center p-6 text-left md:items-start lg:px-20 lg:text-center lg:items-center xl:container xl:mx-auto xl:py-14">
          <div className="w-9/12 mx-8 text-2xl md:mx-0 md:text-3xl">
            {messages.en.ourOneStopSolution}
          </div>
          <div className="py-6 text-center md:text-left lg:text-center">
            {messages.en.ourOneStopSolutionText}
          </div>
          <Solutions />
        </div>
        {
          //#endregion
        }

        {
          //#region Partnerships
        }
        <div
          id="partners"
          className="relative flex flex-col justify-center px-6 pt-10 text-center bg-white lg:flex-row lg:px-0"
        >
          <div
            className="z-10 bg-cover pb-14"
            style={{
              backgroundImage: `url(${bgMap})`,
              backgroundPositionX: isScreenXl ? "0px" : `450px`,
            }}
          >
            <div className="xl:container xl:mx-auto">
              <div className="flex flex-col lg:flex-row lg:px-20 lg:text-left lg:items-center lg:py-32">
                <div className="lg:w-5/12 lg:pr-16">
                  <div className="pt-6 mb-10 text-2xl">
                    <span className="text-blue-600">
                      20+ {messages.en.years}
                    </span>
                    <br />
                    {messages.en.ofPartnershipsAndCustomers}
                  </div>
                  <div className="mb-10">{messages.en.partnersText}</div>
                </div>
                <div className=" lg:mx-0 lg:flex-1">
                  <ImageSlider slides={partnerSlides} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          //#endregion
        }

        {
          //#region Resources
        }

        {isScreenLg ? (
          <div className="flex items-center px-20 xl:container xl:mx-auto py-14">
            <div className="w-1/2 pr-16">
              <div className="py-6 text-3xl">{messages.en.resources}</div>
              <div className="py-6">{messages.en.resourcesText}</div>
              <div className="flex items-center w-full py-4 pb-10">
                <StaticImage
                  src="../images/pdf-icon.png"
                  alt=""
                  className="w-14"
                />
                <div className="flex flex-col md:justify-center md:ml-6">
                  <div className="py-3 leading-tight text-blue-600 md:py-0 md:text-lg">
                    {messages.en.downloadCompanyProfile} 2021
                  </div>
                  <div>PDF, 1MB</div>
                </div>
              </div>
              <Button className="w-60">{messages.en.viewOtherResources}</Button>
            </div>
            <div className="relative w-1/2 h-full ">
              <div className="absolute top-0 left-0 z-10 flex flex-col justify-between w-1/2 h-full px-6 py-8 text-lg text-left text-white bg-black bg-opacity-80 rounded-l-xl">
                <div>
                  {messages.en.ningboJintai}
                  <br />
                  {messages.en.rubberAndPlasticCo}
                </div>
                <div className="text-3xl leading-10 md:text-4xl md:leading-tight">
                  {messages.en.company}
                  <br />
                  {messages.en.profile}
                </div>
                <div className="md:text-lg">2021</div>
              </div>
              <img
                src={brazingTablet}
                alt=""
                className="w-auto h-full rounded-xl"
              />
            </div>
          </div>
        ) : (
          <div className="flex flex-col items-center p-6 text-center md:items-start md:text-left">
            <div className="py-6 text-2xl md:text-3xl">
              {messages.en.resources}
            </div>
            <div className="relative md:w-full">
              <div className="absolute top-0 left-0 z-10 flex flex-col justify-between w-2/3 h-full px-6 py-8 text-left text-white bg-black md:w-5/12 bg-opacity-80 md:text-lg">
                <div>
                  {messages.en.ningboJintai}
                  <br />
                  {messages.en.rubberAndPlasticCo}
                </div>
                <div className="text-3xl leading-10 md:text-4xl md:leading-tight">
                  {messages.en.company}
                  <br />
                  {messages.en.profile}
                </div>
                <div className="md:text-lg">2021</div>
              </div>
              <img
                src={isScreenMd ? brazingTablet : brazing}
                alt=""
                className="w-full h-auto"
              />
            </div>
            <div className="flex flex-col items-center w-1/2 py-4 md:flex-row md:pb-10">
              <StaticImage
                src="../images/pdf-icon.png"
                alt=""
                className="w-1/4 md:w-14"
              />
              <div className="md:flex md:flex-col md:justify-center md:ml-3">
                <div className="py-3 leading-tight text-blue-600 md:py-0 md:text-lg">
                  {messages.en.downloadCompanyProfile} 2021
                </div>
                <div>PDF, 1MB</div>
              </div>
            </div>
            <Button
              big={isScreenMd ? true : false}
              className="w-full py-6 text-xl md:w-5/12 md:py-4 lg:w-80"
            >
              {messages.en.viewOtherResources}
            </Button>
          </div>
        )}
        {
          //#endregion Resources
        }

        {
          //#region Location
        }
        <div className="flex flex-col items-center text-center md:text-left md:items-left lg:text-center lg:items-center lg:w-full lg:px-20">
          <div className="w-full md:px-6 xl:mx-0 xl:w-1/3">
            <div className="px-6 pt-8 md:px-0">
              <div className="py-3">{messages.en.easyAccessToPorts}</div>
              <div className="pb-4 text-2xl">{messages.en.officeContact}</div>
              <div className="pb-6">{messages.en.geographicalAdvantage}</div>
            </div>
            {!isScreenLg && (
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1780019.6807560984!2d120.31818813987971!3d29.3819716564295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x344ded5bede527e3%3A0x80d606e612e7b65a!2sNingbo%20Jintai%20Rubber%20and%20Plastic%20Co.%2C%20Ltd.!5e0!3m2!1sen!2shk!4v1660715067327!5m2!1sen!2shk"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full h-96"
              ></iframe>
            )}
          </div>

          <div className="flex flex-col items-stretch w-full text-center md:px-6 pb-14 lg:px-20 lg:flex-row lg:container lg:mx-auto lg:mt-6">
            <div className="flex flex-col items-center w-full pb-12 bg-white md:w-full lg:w-1/2 lg:items-start lg:pl-20 lg:h-full lg:justify-center md:py-6 ">
              <div className="items-center lg:flex lg:pb-12">
                <div className="flex justify-center mt-10 mb-2 text-white lg:m-0">
                  <PhoneIcon className="w-10 h-10 p-2 bg-blue-600 rounded-full xl:w-12 xl:h-12" />
                </div>
                <div className="lg:flex lg:flex-col lg:ml-6 lg:items-start lg:text-left">
                  <div className="text-lg text-blue-600">+86 574 65292958</div>
                  <div>{messages.en.phoneNumber}</div>
                </div>
              </div>

              <div className="items-center lg:flex lg:pb-12">
                <div className="flex justify-center mt-10 mb-2 text-white lg:m-0">
                  <MailIcon className="w-10 h-10 p-2 bg-blue-600 rounded-full xl:w-12 xl:h-12" />
                </div>
                <div className="lg:flex lg:flex-col lg:ml-6 lg:items-start lg:text-left">
                  <div className="text-lg text-blue-600">info@jintai.com</div>
                  <div>{messages.en.emailAddress}</div>
                </div>
              </div>

              <div className="items-center lg:flex">
                <div className="flex justify-center mt-10 mb-2 text-white lg:m-0">
                  <LocationMarkerIcon className="w-10 h-10 p-2 bg-blue-600 rounded-full xl:w-12 xl:h-12" />
                </div>
                <div className="lg:flex lg:flex-col lg:ml-6 lg:items-start lg:text-left">
                  <div className="mb-1 text-lg leading-tight text-blue-600">
                    {messages.en.meilinIndustrialZone},
                    <br />
                    {messages.en.ningboZhejianChina}
                  </div>
                  <div>{messages.en.companyLocation}</div>
                </div>
              </div>
            </div>
            {isScreenLg && (
              <div className="relative w-1/2">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1780019.6807560984!2d120.31818813987971!3d29.3819716564295!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x344ded5bede527e3%3A0x80d606e612e7b65a!2sNingbo%20Jintai%20Rubber%20and%20Plastic%20Co.%2C%20Ltd.!5e0!3m2!1sen!2shk!4v1660715067327!5m2!1sen!2shk"
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                  className="absolute w-full h-full"
                ></iframe>
              </div>
            )}
            <div id="contactForm" />
          </div>

          <div className="px-6 my-8 md:w-full lg:w-2/3">
            <div className="mb-6 text-2xl">{messages.en.fillInTheForm}</div>
            <div className="mx-1 mb-8">{messages.en.fillInTheFormText}</div>
            <form {...formAttributes} action="/">
              <input type="hidden" name="form-name" value="contact" />
              <div className="gap-5 md:grid md:grid-cols-2">
                <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                  <label htmlFor="name" className="sr-only">
                    {messages.en.name}
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder={messages.en.name}
                    required
                  />
                </div>
                <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                  <label htmlFor="company" className="sr-only">
                    {messages.en.company}
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder={messages.en.company}
                    required
                  />
                </div>
                <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                  <label htmlFor="email" className="sr-only">
                    {messages.en.emailAddress}
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder={messages.en.emailAddress}
                    required
                  />
                </div>
                <div className="mb-8 md:col-span-1 md:row-span-1 md:mb-0">
                  <label htmlFor="phone" className="sr-only">
                    {messages.en.phoneNumber}
                  </label>
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="block w-full py-3 text-center border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                    placeholder={messages.en.phoneNumber}
                    required
                  />
                </div>
                <div className="mb-8 md:col-span-2 md:row-span-1 md:mb-4">
                  <label
                    htmlFor="comment"
                    className="block text-sm font-medium text-gray-700 sr-only"
                  >
                    {messages.en.howCanWeHelp}
                  </label>
                  <textarea
                    rows={!isScreenXl && !isScreenMd ? 4 : 6}
                    name="comment"
                    id="comment"
                    className="block w-full p-3 text-sm border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 md:text-base"
                    placeholder="How can we help?"
                  />
                </div>
                <Button
                  submit
                  big
                  className="w-full py-6 text-xl md:py-3 md:w-9/12 lg:col-span-2 lg:w-full"
                >
                  {messages.en.submit}
                </Button>
              </div>
            </form>
          </div>
        </div>
        {
          //#endregion Location
        }

        <Footer />
      </div>
    </main>
  );
};

export default IndexPage;
